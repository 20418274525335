<template>
  <div class="tw-space-y-3">
    <div
        v-for="(item, i) in items"
        :key="i">
      <div class="tw-border tw-border-gray-n1 tw-rounded-xl tw-py-4 tw-px-6">
        <div class="tw-flex">
          <div v-if="showImage && (item.type.name === 'Product' || item.type.name === 'Package')" :class="{'tw-flex tw-items-center tw-justify-center tw-bg-gray-n1 tw-rounded-lg' : !item.image}" class="tw-flex-grow-0 tw-flex-shrink-0 tw-mr-4" style="flex-basis: 247px; height: 173px">
            <img v-if="item.image" :src="item.image"  class="tw-w-full tw-h-full tw-object-center tw-object-cover tw-rounded-lg" />
            <div v-else><image-icon class="tw-w-14 tw-h-14 tw-text-gray-n2"/></div>
          </div>
          <div class="tw-flex-grow">
            <div class="tw-flex tw-justify-between">
              <div class="tw-font-semibold tw-leading-relaxed tw-mb-3">
                <div class="tw-text-lg tw-text-black tw-mb-2">
                  {{ item.name }}
                </div>
                <div class="tw-text-sm tw-text-gray-n3">
                  {{ item.categories.map(item => item.name).join(', ') }}
                </div>
              </div>
              <div class="tw-flex tw-items-start tw-space-x-3 tw-mb-3">
                <button
                    :loading="(iloading || loading) && selected == item.id"
                    v-if="!item.is_published"
                    type="button"
                    v-on:click="destroy(item.id)"
                    class="tw-border tw-border-solid tw-border-red tw-rounded-xl tw-font-semibold tw-text-red tw-py-3 tw-px-8 tw-flex tw-items-center tw-leading-relaxed"
                >
                  <x-circle-icon class="tw-w-5 tw-h-5 tw-mr-2"/>
                  {{ $t('vendor.offer.delete') }}
                </button>

                <router-link
                    v-if="item.type.name === 'Product'"
                    :to="{name: 'vendor.product.edit', params: { id: item.id }}"
                    class="tw-border tw-border-black tw-rounded-xl tw-font-semibold tw-text-black tw-py-3 tw-px-8 tw-flex tw-items-center tw-leading-relaxed"
                >
                  <edit2-icon class="tw-w-5 tw-h-5 tw-mr-2"/>
                  {{ $t('vendor.offer.edit') }}
                </router-link>
                <router-link
                    v-else-if="item.type.name === 'Add-on'"
                    :to="{name: 'vendor.add-on.edit', params: { id: item.id }}"
                    class="tw-border tw-border-black tw-rounded-xl tw-font-semibold tw-text-black tw-py-3 tw-px-8 tw-flex tw-items-center tw-leading-relaxed"
                >
                  <edit2-icon class="tw-w-5 tw-h-5 tw-mr-2"/>
                  {{ $t('vendor.offer.edit') }}
                </router-link>
                <router-link
                    v-else-if="item.type.name === 'Fees'"
                    :to="{name: 'vendor.fees.edit', params: { id: item.id }}"
                    class="tw-border tw-border-black tw-rounded-xl tw-font-semibold tw-text-black tw-py-3 tw-px-8 tw-flex tw-items-center tw-leading-relaxed"
                >
                  <edit2-icon class="tw-w-5 tw-h-5 tw-mr-2"/>
                  {{ $t('vendor.offer.edit') }}
                </router-link>
                <router-link
                    v-else-if="item.type.name === 'Package'"
                    :to="{name: 'vendor.package.edit', params: { id: item.id }}"
                    class="tw-border tw-border-black tw-rounded-xl tw-font-semibold tw-text-black tw-py-3 tw-px-8 tw-flex tw-items-center tw-leading-relaxed"
                >
                  <edit2-icon class="tw-w-5 tw-h-5 tw-mr-2"/>
                  {{ $t('vendor.offer.edit') }}
                </router-link>            
              </div>
            </div>

            <div class="tw-text-black tw-text-sm tw-leading-relaxed tw-mb-3" v-html="item.description">
            </div>

            <div class="tw-text-black tw-text-sm tw-leading-relaxed tw-mb-3">
              <span v-if="item.products.length > 0 && item.type.name === 'Package'">
               <p><strong>Selected Services & Goods</strong></p>
                <span v-for="(product,index) in item.products" :key="index">
                {{index > 0 ? '+':null}} {{product.name}} {{product.price | currency}}
                <!--
                  To include fees price value from Included Service & Goods to Package 
                {{product.total_price_without_discount | currency}}
                -->
                </span>
              </span>

            </div>

            <div class="tw-flex tw-justify-between">
              <p class="tw-text-sm tw-leading-relaxed tw-font-semibold tw-text-gray-n3 tw-mb-0">
                <span>{{ $t('vendor.offer.price') }}: {{ item.total_price_without_discount | currency }}</span>
                <span v-if="item.type.name == 'Package' || item.type.name == 'Product'">| {{ $t('vendor.offer.discount') }} ({{ item.discount}}%) 
                      | <span class="tw-text-black tw-font-bold">Total: {{item.total_price_with_discount | currency}}</span>
                </span>
              </p>

              <div class="tw-flex tw-justify tw-space-x-3">
                <div class="tw-text-sm tw-text-black tw-flex tw-space-x-2">
                  <span>{{ item.is_published ? $t('vendor.offer.published'):$t('vendor.offer.publish') }}</span>
                  <button @click="publishItem(item.id)" type="button"
                          :class="[item.is_published ? 'tw-bg-green-100' : 'tw-bg-red-100', 'tw-border-solid tw-relative tw-inline-flex tw-flex-shrink-0 tw-h-5 tw-w-10 tw-border-2 tw-border-transparent tw-rounded-full tw-cursor-pointer tw-transition-colors tw-ease-in-out tw-duration-200 focus:tw-outline-none ']" role="switch" aria-checked="false">
                    <span class="tw-sr-only">Use setting</span>
                    <span aria-hidden="true" :class="[item.is_published ? 'tw-translate-x-5 tw-bg-green' : 'tw-translate-x-0 tw-bg-red', 'tw-pointer-events-none tw-inline-block tw-h-4 tw-w-4 tw-rounded-full tw-shadow tw-transform tw-ring-0 tw-transition tw-ease-in-out tw-duration-200']"></span>
                  </button>
                </div>
              
                <div v-if="!item.personalized" class="tw-text-sm tw-text-black tw-flex tw-space-x-1">
                  <span>|</span>
                  <v-tooltip 
                      top
                      >
                      <template v-slot:activator="{ on, attrs }">
                          <arrow-up-circle-icon 
                            v-bind="attrs"
                            v-on="on"
                            size="1.5x" class="custom-class tw-cursor-pointer" @click="sortBy(item,0,false,false,true,false)"
                          ></arrow-up-circle-icon>
                      </template>
                      Move Up!
                    </v-tooltip>
                  <v-tooltip 
                      top
                      >
                      <template v-slot:activator="{ on, attrs }">
                        <arrow-down-circle-icon 
                            v-bind="attrs"
                            v-on="on"
                             size="1.5x" class="custom-class tw-cursor-pointer" @click="sortBy(item,0,false,false,false,true)"></arrow-down-circle-icon>
                      </template>
                      Move Down!
                    </v-tooltip>
                   <!--
                    <trending-up-icon size="1.5x" class="custom-class tw-cursor-pointer" @click="sortBy(item,0,true,false,false,false)"></trending-up-icon>
                    <trending-down-icon size="1.5x" class="custom-class tw-cursor-pointer" @click="sortBy(item,0,false,true,false,false)"></trending-down-icon>
                    -->
                </div>
              </div>

            </div>
          </div>
         
        </div>
        


      </div>
    </div>
  </div>
</template>


<script>

import { ImageIcon, XCircleIcon, Edit2Icon, ArrowUpCircleIcon, ArrowDownCircleIcon  } from "vue-feather-icons";

export default {
  props: ['items', 'iloading', 'showImage'],
  name: "VendorViewProduct",
  components: {
    ImageIcon,
    XCircleIcon,
    Edit2Icon,
    ArrowUpCircleIcon,
    ArrowDownCircleIcon
  },
  data() {
    return {
      loading: false,
      selected: null,
      sort: {
        place: 1,
        first: false,
        last: false,
        up: false,
        down: false
      }
    }
  },

  methods: {

    sortBy(item,place,first,last,up,down) {

      this.sort.place = place;
      this.sort.first = first;
      this.sort.last = last;
      this.sort.up = up;
      this.sort.down = down;

      this.loading = true;
      let uri = '/api/vendor/products/' + item.id + '/sort';
      this.$http.post(uri, this.sort )
          .then(() => {
            this.$emit('fetchItems', {
              id: item.id,
              delete: false
            });
          }).finally(() =>
          this.loading = false
      )
    },    
    deleteItem(id) {
      this.selected = id;
      this.loading = true;
      let uri = '/api/vendor/products/' + id;
      this.$http.delete(uri)
          .then(() => {
            //this.fetchItems();
            this.$emit('fetchItems', {
              id: id,
              delete: true
            });
          }).finally(() =>
          this.loading = false
      );
    },
    publishItem(id) {
      this.selected = id;
      this.loading = true;
      let uri = '/api/vendor/products/publish/' + id;
      this.$http.patch(uri)
          .then(() => {
            //this.fetchItems();
            this.$emit('fetchItems', {
              id: id,
              delete: false
            });
          }).finally(() =>
          this.loading = false
      )
    },
    destroy(vId) {
      this.$toast.question('Are you sure about that?', "Confirm", {
        timeout: 20000,
        close: false,
        overlay: true,
        displayMode: 'once',
        id: 'address',
        zindex: 999,
        title: 'Hey',
        position: 'center',
        buttons: [
          ['<button><b>YES</b></button>', (instance, toast) => {

            this.deleteItem(vId);

            instance.hide({transitionOut: 'fadeOut'}, toast, 'button');

          }, true],
          ['<button>NO</button>', function (instance, toast) {

            instance.hide({transitionOut: 'fadeOut'}, toast, 'button');

          }],
        ]
      });
    },
  }

}
</script>



