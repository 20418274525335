<template>
  <div class="tw-mb-40">
    <v-text-field v-if="1==2" v-model="query"
                  clearable
                  prepend-inner-icon="icon-search"
                  placeholder="Start typing to search"
    >
    </v-text-field>
    <div class="tw-mt-4">
      <div>
        <div class="tw-border-b tw-border-gray-n0 tw-flex tw-justify-between tw-items-end">
          <nav class="tw--mb-px tw-flex tw-space-x-8 tw-leading-relaxed" aria-label="Tabs">
            <a @click.prevent="activePage = 'Product'" :class="[activePage === 'Product' ? 'tw-border-black tw-text-black' : 'tw-border-transparent tw-text-gray-n2 hover:tw-text-gray-n3 hover:tw-border-gray-n3', 'tw-no-underline tw-group tw-inline-flex tw-items-center tw-py-3 tw-px-1 tw-border-b-2 tw-font-medium tw-text-sm tw-relative']" href="#">
              <span>{{$t('vendor.service_goods')}}</span>
              <ml-pop-up v-if="activePage === 'Product' && getHelpData('Product').length" class="tw-w-5 tw-h-5 tw-absolute tw--top-1 tw--right-1"
                         :title="getHelpData('Product')[0].question"
                         :description="getHelpData('Product')[0].answer"
                         stroke-width="2" />
            </a>
            <a @click.prevent="activePage = 'Add-on'" href="#" :class="[activePage === 'Add-on' ? 'tw-border-black tw-text-black' : 'tw-border-transparent tw-text-gray-n2 hover:tw-text-gray-n3 hover:tw-border-gray-n3', 'tw-no-underline tw-group tw-inline-flex tw-items-center tw-py-3 tw-px-1 tw-border-b-2 tw-font-medium tw-text-sm tw-relative']">
              <span>{{$t('vendor.addons')}}</span>
              <ml-pop-up v-if="activePage === 'Add-on' && getHelpData('Add-on').length" class="tw-w-5 tw-h-5 tw-absolute tw--top-1 tw--right-1"
                         :title="getHelpData('Add-on')[0].question"
                         :description="getHelpData('Add-on')[0].answer"
                         stroke-width="2" />
            </a>

            <a @click.prevent="activePage = 'Package'" :class="[activePage === 'Package' ? 'tw-border-black tw-text-black' : 'tw-border-transparent tw-text-gray-n2 hover:tw-text-gray-n3 hover:tw-border-gray-n3', 'tw-no-underline tw-group tw-inline-flex tw-items-center tw-py-3 tw-px-1 tw-border-b-2 tw-font-medium tw-text-sm tw-relative']" href="#">
              <span>{{$t('vendor.packages')}}</span>
              <ml-pop-up v-if="activePage === 'Package' && getHelpData('Package').length" class="tw-w-5 tw-h-5 tw-absolute tw--top-1 tw--right-1"
                         :title="getHelpData('Package')[0].question"
                         :description="getHelpData('Package')[0].answer"
                         stroke-width="2" />
            </a>

            <a @click.prevent="activePage = 'Fees'" href="#" :class="[activePage === 'Fees' ? 'tw-border-black tw-text-black' : 'tw-border-transparent tw-text-gray-n2 hover:tw-text-gray-n3 hover:tw-border-gray-n3', 'tw-no-underline tw-group tw-inline-flex tw-items-center tw-py-3 tw-px-1 tw-border-b-2 tw-font-medium tw-text-sm tw-relative']">
              <span>{{$t('vendor.fees')}}</span>
              <ml-pop-up v-if="activePage === 'Fees' && getHelpData('Fees').length" class="tw-w-5 tw-h-5 tw-absolute tw--top-1 tw--right-1"
                         :title="getHelpData('Fees')[0].question"
                         :description="getHelpData('Fees')[0].answer"
                         stroke-width="2" />
            </a>

            <a @click.prevent="activePage = 'Quote'" href="#" :class="[activePage === 'Quote' ? 'tw-border-black tw-text-black' : 'tw-border-transparent tw-text-gray-n2 hover:tw-text-gray-n3 hover:tw-border-gray-n3', 'tw-no-underline tw-group tw-inline-flex tw-items-center tw-py-3 tw-px-1 tw-border-b-2 tw-font-medium tw-text-sm tw-relative']">
              <span>{{$t('vendor.custom_quotes')}}</span>
              <ml-pop-up v-if="activePage === 'Quote' && getHelpData('Quote').length" class="tw-w-5 tw-h-5 tw-absolute tw--top-1 tw--right-1"
                         :title="getHelpData('Quote')[0].question"
                         :description="getHelpData('Quote')[0].answer"
                         stroke-width="2" />
            </a>

          </nav>
          <router-link
              class="tw-bg-black tw-text-white tw-font-semibold tw-leading-relaxed 
              tw-px-8 tw-py-3 tw-rounded-xl tw-mb-2"
               v-if="activePage === 'Product'"
              :to="{name: 'vendor.product.create'}"
          >
            {{$t('vendor.create_new')}}
          </router-link>          
          <router-link
              class="tw-bg-black tw-text-white tw-font-semibold tw-leading-relaxed tw-px-8 tw-py-3 tw-rounded-xl tw-mb-2"
              v-else-if="activePage === 'Package'"
              :to="{name: 'vendor.package.create'}"
          >
            {{$t('vendor.create_new')}}
          </router-link>
          <router-link
              class="tw-bg-black tw-text-white tw-font-semibold tw-leading-relaxed tw-px-8 tw-py-3 tw-rounded-xl tw-mb-2"
              v-else-if="activePage === 'Add-on'"
              :to="{name: 'vendor.add-on.create'}"
          >
            {{$t('vendor.create_new')}}
          </router-link>
          <router-link
              class="tw-bg-black tw-text-white tw-font-semibold tw-leading-relaxed tw-px-8 tw-py-3 tw-rounded-xl tw-mb-2"
              v-else-if="activePage === 'Fees'"
              :to="{name: 'vendor.fees.create'}"
          >
            {{$t('vendor.create_new')}}
          </router-link>
          <button @click="saveTos()"
          v-else-if="activePage === 'Terms'"
            :disabled="!valid"
            class="tw-bg-black tw-text-white tw-font-semibold tw-leading-relaxed tw-px-8 tw-py-3 tw-rounded-xl tw-mb-2">
            {{$t('vendor.save_toc')}}
          </button>      
          <div
              class="tw-bg-black tw-text-white tw-invisible tw-font-semibold tw-leading-relaxed tw-px-8 tw-py-3 tw-rounded-xl tw-mb-2"
              v-else
          >
            Placeholder
          </div>
        </div>
      </div>
      <v-tabs
          v-model="activePage"
          background-color="white"
          color="deep-purple accent-4"
          left
      >
        <v-tab-item value="Product">
              <div>
                <VendorViewProduct :showImage="true" v-if="items" @fetchItems="itemUpdated" :iloading="loading"
                                   :items="items"/>
                <div v-if="!loading && !items">
                  <div class="container">
                    <v-alert color="#2A3B4D" dark icon="mdi-firework" dense>
                      {{$t('vendor.products_not_found')}}
                    </v-alert>
                  </div>
                </div>
              </div>
        </v-tab-item>

        <v-tab-item value="Add-on">
          <div>
            <VendorViewProduct v-if="items" :iloading="loading" @fetchItems="itemUpdated" :items="items" />
            <div v-if="!loading && !items">
              <div class="container">
                <v-alert
                    color="#2A3B4D"
                    dark
                    icon="mdi-firework"
                    dense
                >
                  {{$t('vendor.addons_not_found')}}
                </v-alert>
              </div>
            </div>
          </div>
        </v-tab-item>

        <v-tab-item value="Package">
          <div>
              <div>
                <VendorViewProduct :showImage="true" v-if="items" @fetchItems="itemUpdated" :iloading="loading"
                                   :items="items"/>
                <div v-if="!loading && !items">
                  <div class="container">
                    <v-alert color="#2A3B4D" dark icon="mdi-firework" dense>
                      {{$t('vendor.products_not_found')}}
                    </v-alert>
                  </div>
                </div>
              </div>
          </div>
        </v-tab-item>

        <v-tab-item value="Fees">
          <div>
            <VendorViewProduct v-if="items" :iloading="loading" @fetchItems="itemUpdated" :items="items" />
            <div v-if="!loading && !items">
              <div class="container">
                <v-alert
                    color="#2A3B4D"
                    dark
                    icon="mdi-firework"
                    dense
                >
                  {{$t('vendor.addons_not_found')}}
                </v-alert>
              </div>
            </div>
        
          </div>
        </v-tab-item>

        <v-tab-item value="Quote">
              <div>
                <VendorViewProduct v-if="items" :showImage="true" :iloading="loading" @fetchItems="itemUpdated" :items="items" />
                <div v-if="!loading && items.length === 0">
                  <div class="container">
                    <v-alert
                        color="#2A3B4D"
                        dark
                        icon="mdi-firework"
                        dense
                    >
                      {{$t('vendor.custom_quotes_not_found')}}
                    </v-alert>
                  </div>
                </div>
              </div>
        </v-tab-item>
  
      </v-tabs>
    </div>
    <v-pagination
        v-if="!loading && items.length !== 0 && activePage != 'Terms'"
        v-model="pagination"
        :length="meta.last_page"
        :total-visible="meta.total"
        class="tw-mt-8"
    ></v-pagination>


  </div>
</template>

<script>

import VendorViewProduct from '../../../components/VendorProduct.vue'
import MlPopUp from "@/components/help/MLPopUp";

//import DataFilter from '../../../components/DataFilter.vue';
import { mapGetters } from 'vuex'
import i18n from '@/i18n'

export default {
  middleware: ['auth', 'vendor', 'is-vendor-active'],
  components: {
    VendorViewProduct,
    MlPopUp
    //DataFilter
  },
  metaInfo() {
    return {
      title: i18n.t('offers'),
      meta:[
        {
          name: 'description',
          content: i18n.t('description.vendor/offers'),
        },
      ]
    }
  },
  data() {
    return {
      pagination: 1,
      query: null,
      meta: [],
      tos: '',
      vendor: null,
      activePage: 0,
      items: [],
      addons: [],
      filter: null,
      loading: false,
      errored: false,
      error: null,
      valid: true,
      orderitems: [
        {'key': '1', 'name': 'Name A-Z', 'value': 'name', 'icon': 'mdi-alphabetical', 'color': 'black'},
        {'key': '2', 'name': 'Name Z-A', 'value': '-name', 'icon': 'mdi-alphabetical', 'color': 'grey'},
        {'key': '3', 'name': 'Latest created', 'value': '-id', 'icon': 'mdi-chevron-double-right', 'color': 'black'},
        {'key': '4', 'name': 'Oldest created', 'value': 'id', 'icon': 'mdi-chevron-double-left', 'color': 'grey'},
      ],
      helpData:[
        {
          id: 'Product',
          question: 'What are Goods & Services?',
          answer: '<br> Goods & Services are vendor offerings that couples can book and purchase individually. List as many as you would like to offer.',
        },
        {
          id: 'Add-on',
          question: 'What are Add-ons?',
          answer: '<br> Add-ons are additional items that can be added to your Goods & Services, Packages or Custom Quotes which cannot be sold separately. When you include an Add-on to Goods & Services, couples have the option to select and purchase it for an additional fee. When you include an Add-on to a Package, it is automatically added to that Package.',
        },
        {
          id: 'Package',
          question: 'What are Packages?',
          answer: '<br> Packages are created if you are interested in “bundling” specific services together as a single Offer by combining your Goods & Services, Add-ons and Fees. When you create a Package, the Goods & Services, Add-ons and Fees you select are automatically added to that Package.',
        },
        {
          id: 'Fees',
          question: 'What are Fees?',
          answer: '<br> Fees can be added to your Goods & Services, Packages or Custom Quotes to cover travel-related, administrative, or other additional costs as part of your Offer. Once you add Fees, they will be displayed so you can select them for future Offers.',
        },
        {
          id: 'Quote',
          question: 'What are Custom Quotes?',
          answer: '<br> Custom Quotes are provided to couples who want to hire you, but do not see what they are looking for in your Offers.' +
              '<br><br> 1. Couple sends Quote Request to vendor which turns on a red button in their profile ' +
              '<br> 2. Vendor receives notification and email ' +
              '<br> 3. Vendor goes to Custom Quotes section in Dashboard and creates custom Offer for couple ' +
              '<br> 4. Couple receives notification and email ' +
              '<br> 5. Couple reviews offer and accepts or rejects Custom Quote ' +
              '<br> 6. If Couple accepts Custom Quote, Vendor accepts, and it turns into a Booking',
        },
      ]
    }
  },

  mounted() {
    this.loadInitData();
    this.activePage = 'Product';
    if (this.$route.query.page) {
      this.activePage = this.$route.query.page;
    }

  },
  watch: {
    "pagination": function () {
      // console.log("pagination");
      this.fetchItems(null, this.selectedPage);
    },
    "$route": function () {
      if (this.$route.name == 'vendor.offers') {
        // console.log("$route");
        this.fetchItems(null, this.selectedPage);
      }
      //console.log(this.$route);
    },

    activePage: function () {
      //console.log("activePage");
      this.pagination = 1;
      // console.log(this.activePage);
      if(this.activePage != 'Terms') this.fetchItems(null, this.selectedPage);
    },
    query: function () {

      if (this.query == null || this.query === "") {
        this.fetchItems(null, this.selectedPage);
      }

      if (this.query.length < 3) return;

      if (this.loading) return

      this.loading = true

      setTimeout(function () {
        //console.log("query");
        this.pagination = 1;
        this.fetchItems(this.query, this.selectedPage)
      }.bind(this), 200);

    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      filterParams: 'filter/getFilterParams',
    }),
    selectedPage() {
      if (this.activePage === "Package") {
        return "Package";
      } else if (this.activePage === "Add-on") {
        return "Add-on";
      } else if (this.activePage === "Fees") {
        return "Fees";
      } else if (this.activePage === "Product" || this.activePage === "Quote"){
        return "Product";
      }else {
        return "Product";
      }

    },
    isPersonalized() {
      if (this.activePage === "Quote") {
        return 1;
      } else {
        return 0;
      }
    },
    productItems() {
      return this.items.filter(item => !item.personalized && item.type.name === "Product")
    },    
    packageItems() {
      return this.items.filter(item => !item.personalized && item.type.name === "Package")
    },
    productExist() {
      return this.productItems.length > 0
    },
    quoteItems() {
      return this.items.filter(item => item.personalized && item.type.name === "Product")
    },
    quoteExist() {
      return this.quoteItems.length > 0
    },
    addonItems() {
      return this.items.filter(item => item.type.name === "Add-on")
    },
    addonExist() {
      return this.addonItems.length > 0
    },
  },
  methods: {
    getHelpData(id){
      return this.helpData.filter(item => item.id === id)
    },
    async loadInitData() {
      await this.getVendor();
      if (!this.loading) await this.fetchItems(null, this.selectedPage);

    },   
    getProducts(e) {
      this.filter = e;
      this.fetchItems(null, this.selectedPage);
      //console.log(e);
    },
    itemUpdated(event) {

      if (event.delete) {
        this.fetchItems(null, this.selectedPage);
        //var index = this.items.indexOf(event.id);
        //this.items.splice(index, 1);
      } else {
        //this.fetchItems(null);
        this.fetchItems(null, this.selectedPage);
        //this.fetchAddons();
      }

    },
    /*
    async fetchAddons()
        {
        this.loading = true;
        let uri = '/api/vendor/addons';
        this.$http.get(uri)
        .then((response) => {
            this.addons = response.data.data,
            this.loading = false
        });

    },
    */
    async fetchItems(query, type) {

      //console.log("fetchItems",type);
      this.loading = true;

      console.log(query); // izbaciti ako se nece koristiti side filter, koristeno za direct search

      // "filter[personalized]": [0,this.isPersonalized],

      const params = {
        "page": this.pagination ? this.pagination : this.$route.query.page,
        "filter[personalized]": this.isPersonalized,
        //"filter[queryData]": query ? query:null,
        "filter[queryData]": this.filter ? this.filterParams["filter[queryData]"] : null,
        //"filter[name]": this.filter ? this.filterParams["filter[name]"]:null,
        "filter[category_in]": this.filter ? this.filterParams["filter[category_in]"] : null,
        "filter[type]": type,
        "sort": this.filter ? this.filterParams["sort"] : null,
      };


      let uri = '/api/vendor/products';
      this.$http.get(uri, {params})
          .then((response) => {
            this.items = response.data.data,
                this.meta = response.data.meta,
                this.loading = false
          });

    },
    getVendor() {
      this.loading = true;
      let uri = '/api/vendors/' + this.user.id + "?include=companies,categories";
      this.$http.get(uri)
          .then((response) => {
            this.vendor = response.data.data
          })
          .catch((error) => {
            this.errored = true;
            this.error = error;
          })
          .finally(() => this.loading = false);
    },
    /*
    fetchResults(query) {
        this.loading = true;
        const params = {
            "query" : query
        };
        this.$http.get('api/vendor/products/search', {params})
            .then((response)  => {
                this.items = response.data.data
            })
            .catch(error => {
                console.log('Error loading data: ' + error)
                this.errored = true
            })
            .finally(() => this.loading = false)
    },
    */
  }
}
</script>
